// @flow
import k from "src/i18n/keys";

export const customerSuccessTeam = [
  "2Kro7kPQz4XDtZAJRQOJejMzNmg2",
  "RbcIuetAQAeIn7lm96wn7iYcHC32",
  "tuNlkbY8EkNf1wue2LhEpZkkqIk1",
  "ufSdNZUAM2dzS9g24dNE4e5oNnv2",
  "rCqMTLd7mzaDsGdALFeBtYt6InJ2"
];

export const BETA_TESTERS = [
  "GJuQurhdUOR9liGN1xGuACGM7pD2", // avinash.sultanpur@unifize.com
  "2Kro7kPQz4XDtZAJRQOJejMzNmg2" // akanksha.sharma@unifize.com
];

export const ME_FILTER = "current-user";

export const userStatus = {
  pending: "Pending",
  active: "Active",
  disabled: "Disabled"
};

export const userStatusTranslated = {
  Pending: k.PENDING,
  Active: k.ACTIVE,
  Disabled: k.DISABLED1
};

// Orgs that support AI checklist fill
export const aiEnabledOrgs = [1, 540, 877];
